import Vue from 'vue';
import mergePdf from '@/utils/margePdfs';
import b64toBlob from '@/utils/b64ToBlob';

export default Vue.extend({
  methods: {
    async printAllLabels(labels: string[]): Promise<void> {
      const mergedLabels = await mergePdf(labels);
      this.print(mergedLabels);
    },
    print(labels: string): void {
      const contentType = 'application/pdf';
      const blob = b64toBlob(labels, contentType);
      const pdfWindow = window.open(URL.createObjectURL(blob), '_blank');

      if (pdfWindow) {
        pdfWindow.self.focus();
        pdfWindow.self.print();
      }
    },
  },
});
