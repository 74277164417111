/* eslint-disable no-plusplus */
/* eslint-disable no-return-await */
import PDFMerger from 'pdf-merger-js';
import Buffer from 'buffer';

function base64ToArrayBuffer(base64: string) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

// Files are base64 type
const PdfUtill = async (files: string[]): Promise<string> => {
  const merger = new PDFMerger();
  await Promise.all(
    files.map(async (file) => {
      const bufferFile = base64ToArrayBuffer(file);
      const bufferPdf = Buffer.Buffer.from(bufferFile);
      await merger.add(bufferPdf);
    }),
  );
  const pdfs = await merger
    .saveAsBuffer()
    .then((pdf) => Buffer.Buffer.from(pdf).toString('base64'));

  return pdfs;
};

export default PdfUtill;
